import { Search } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import NoContentComponent from '@/core/components/messages/NoContentComponent.vue';
import DownloadFilterComponent from '@/modules/e-wallet/components/DownloadFilterComponent.vue';
import ListItemComponent from '@/modules/e-wallet/components/ListItemComponent.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        Search,
        ListItemComponent,
        NoContentComponent,
        DownloadFilterComponent,
    },
    data() {
        return {
            initialData: [],
            transactionLogs: [],
            keyword: '',
            userCustomerAccount: null,
            userCustomerCurrentBalance: 0,
            userCustomerAccountDetails: null
        };
    },
    watch: {
        keyword: {
            immediate: true,
            deep: true,
            handler(keyword) {
                if (keyword) {
                    this.onSearch(keyword);
                }
            }
        }
    },
    created() {
        this.initialize();
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isLoading']),
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
    },
    methods: {
        ...mapActions(['setIsLoading']),
        ...mapActions(USERS_STORE, [
            'getUserTransactionLogs',
            'getUserCustomerAccount',
            'getUserCustomerAccountDetails',
            'sendVerificationWithdrawal'
        ]),
        async initialize() {
            const { userId } = this.authenticatedUser;
            this.transactionLogs = await this.getUserTransactionLogs({ userId }).finally(() => { this.setIsLoading(false); });
            if (this.isTradesperson) {
                this.userCustomerAccount = await this.getUserCustomerAccount(userId);
                if (this.userCustomerAccount.accountId) {
                    const { accountId } = this.userCustomerAccount;
                    const response = await this.getUserCustomerAccountDetails({ userId, accountId });
                    const { availableBalance } = response;
                    this.userCustomerAccountDetails = response;
                    this.userCustomerCurrentBalance = availableBalance;
                }
            }
        },
        async onSearch(keyword) {
            const { userId } = this.authenticatedUser;
            await this.getUserTransactionLogs({
                userId,
                options: {
                    search: encodeURIComponent(keyword)
                }
            })
                .then((response) => {
                this.transactionLogs = response;
            })
                .finally(() => {
                this.setIsLoading(false);
            });
        },
    }
});
