<template>
    <div class="no-content-container">
        <inline-svg class="cta-icon" :src="require(`@/assets/images/no-content-placeholder.svg`)"></inline-svg>
        <p class="message">{{tag ? tag : 'Nothing here'}}</p>
    </div>
</template>
<script>
// import { computed } from 'vue';

export default {
  props: ['tag'],
};
</script>
<style lang="scss" scoped>
.no-content-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;

    .message {
        font-family: Mulish;
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.25px;
        color: rgba(42, 42, 42, 0.5);
    }
}
</style>
