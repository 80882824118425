<template>
  <div class="transaction-main-container">
    <div class="box-label">
      <span class="detail-link">
        <el-popover
          placement="bottom"
          :width="230"
          trigger="click"
        >
          <template #reference>
            <span class="download-link">DOWNLOAD</span>
          </template>
          <div class="download-container">
            <div class="download-filter">
              <p>Download Statement</p>
              <el-form class="quote-form" :model="downloadRecord">
                <el-alert :closable="false" v-if="dateErrorMessage" class="date-validation-error" :title="dateErrorMessage" type="error" />
                <el-radio-group :disabled="disableDownload" class="filter-options" @change="onSelectDownloadFilter" v-model="downloadRecord.option">
                  <el-radio :label="filterEntire" size="large">Statement Date</el-radio>
                  <el-date-picker
                    v-model="downloadRecord.startDate"
                    type="date"
                    :format="dateFormat"
                    :placeholder="dateFormat"
                    :disabled="statementDatesDisable"
                  ></el-date-picker>
                  <el-date-picker
                    v-model="downloadRecord.endDate"
                    type="date"
                    :format="dateFormat"
                    :placeholder="dateFormat"
                    :disabled="statementDatesDisable"
                  ></el-date-picker>
                  <el-radio :label="filterRange" size="large">Entire Statement</el-radio>
                </el-radio-group>
              </el-form>
            </div>
            <el-button
              class="download-btn"
              :disabled="disableDownload"
              @click="requestDownload">{{ disableDownload ? 'DOWNLOADING...' : 'DOWNLOAD' }}</el-button>
          </div>
        </el-popover>
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { DATE_FORMAT } from '@/core/constants';

import { USERS_STORE } from '../../../store/modules/users';

const FILTER_ENTIRE = 'entire';
const FILTER_RANGE = 'range';
const END_DATE_VALIDATION_ERROR = 'End date must not be less than start date.';
const EMPTY_DATE_VALIDATION_ERROR = 'Dates must not be empty.';

export default defineComponent({
  name: 'download-filter-component',

  components: {
  },

  data() {
    return {
      dateFormat: DATE_FORMAT,
      filterRange: FILTER_ENTIRE,
      filterEntire: FILTER_RANGE,
      statementDatesDisable: false,
      dateErrorMessage: '',
      downloadRecord: {
        option: FILTER_RANGE
      },
      disableDownload: false
    };
  },

  computed: {
    ...mapGetters(['isTradesperson']),
    ...mapGetters(USERS_STORE, ['user'])
  },

  methods: {
    ...mapActions(USERS_STORE, ['getUserTransactionStatement']),

    onSelectDownloadFilter(value) {
      if (value === this.filterRange) {
        this.statementDatesDisable = true;
      } else {
        this.statementDatesDisable = false;
      }
    },

    requestDownload() {
      const { downloadRecord } = this;
      const { option, startDate, endDate } = downloadRecord;
      const formData = { option };

      this.dateErrorMessage = '';

      if (option === FILTER_RANGE) {
        if (!startDate || !endDate) {
          this.dateErrorMessage = EMPTY_DATE_VALIDATION_ERROR;
        }

        if (startDate && endDate) {
          const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
          const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

          if (formattedEndDate < formattedStartDate) {
            this.dateErrorMessage = END_DATE_VALIDATION_ERROR;
          }

          formData.startDate = formattedStartDate;
          formData.endDate = formattedEndDate;
        }
      }

      if (!this.dateErrorMessage) {
        const payload = {
          userId: this.user.id,
          formData
        };

        this.disableDownload = true;
        this.statementDatesDisable = true;
        this.getUserTransactionStatement(payload)
          .then((response) => {
            if (response.url) {
              window.open(response.url, '_blank');
            }
          })
          .catch(() => {})
          .finally(() => {
            this.disableDownload = false;
            this.statementDatesDisable = false;
          });
      }
    }
  }

});

</script>

<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@import '@/modules/dashboard/styles/dashboard.scss';

.download-link {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.149999976158142px;
  text-align: right;
  color: #FFA500;

  &:hover {
    cursor: pointer;
  }
}

.download-container {
  padding: 10px;

  p {
    color: #2A2A2A;
    font-size: 15px;
    margin: 0 0 8px;
    font-weight: 600px;
  }

  .filter-options {
    :deep(.el-radio__label) {
      color: #2A2A2A;
      font-size: 13px;
    }

    :deep(.el-radio__inner::after) {
      background-color: #FAA100;
      padding: 3px;
    }

    :deep(.el-radio__inner) {
      padding: 9px;
    }
  }

  .download-btn {
    width: 100%;
    background: #FAA200;
    color: #fff;
    border-radius: 100px;
    font-size: 13px;
    border: none;
    height: 40px;
    line-height: 18px;
    margin-top: 20px;
  }

  :deep(.el-date-editor.el-input) {
    width: 100px;
    height: 30px;
    margin: 0 5px 10px 0;
  }

  :deep(.el-input__inner) {
    font-size: 10px;
    padding: 0 0 0 24px;
    height: auto;
    border: none;
  }

  :deep(.el-input__prefix) {
    left: -1px;
    top: -1px;
    color: rgba(250, 161, 0);
  }

  :deep(.clear-icon) {
    left: 6px;
  }

  .date-validation-error {
    padding: 5px 15px 5px 5px;
    transition: 0.5s ease;

    :deep(.el-alert__title) {
      word-break: break-word;
    }

    :deep(.el-alert__closebtn) {
      right: 8px;
      top: 10px;
    }
  }
}

.transaction-list {

  .log {
    float: left;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(42, 42, 42, 0.15);

    .time-category {
      margin: 20px 0;
      font-size: 15px;
      color: rgba(42, 42, 42, 0.5);
    }

    .project-stage {
      float: left;
      line-height: 22px;

      .project-name {
        font-size: 15px;
        color: #2A2A2A;
      }

      .stage-phase {
        font-size: 13px;
        color: rgba(42, 42, 42, 0.5);
      }
    }

    .payment {
      float: right;

      .sum {
        color: #55D462;
        margin-right: 15px;
        font-size: 15px;
      }

      .sum.withdraw {
        color: #f45454;
      }

      .detail-link {
        color: #2A52CB;
        font-size: 13px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .log:last-child {
    border: none;
  }
}

.detail-container {
  padding: 5px;

  p { margin: 5px 0; }

  .project-info {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(42, 42, 42, 0.15);

    .project-title {
      color: #2A2A2A;
      font-size: 15px;
      font-weight: 600;
    }

    .project-refId {
      color: rgba(42, 42, 42, 0.5);
      font-size: 12px;
    }
  }

  .stage-info {
    position: relative;
    padding: 10px 0;

    .stage-title {
      font-size: 13px;
      font-weight: 400;
      color: #2A2A2A;
    }

    .transaction-date {
      font-size: 12px;
      color: rgba(42, 42, 42, 0.5);
    }

    .payment-value {
      margin: 0;
      right: 0;
      position: absolute;
      color: #55D462;
      top: 22px;
      font-size: 15px;
    }

    .payment-value.withdraw {
      color: #f45454;
    }
  }

  .view-project-btn {
    width: 100%;
    background: #FAA200;
    color: #fff;
    border-radius: 100px;
    font-size: 13px;
    border: none;
    height: 40px;
    line-height: 20px;
  }
}

@media (max-width: 1024px) {
  .transaction-main-container {
    .box-label {
      padding: 0 0 0 10px;
      margin-top: 10px;
    }
  }

  .transactions-box {
    margin: 10px 0px 20px;
    border: 1px solid #ccc;
  }

}
</style>
