import { ArrowRight, } from '@element-plus/icons';
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { TransactionLogOperatorEnum } from '@/core/packages/shared-library';
import { USERS_STORE } from '@/store/modules/users';
const __default__ = defineComponent({
    components: {
        ArrowRight,
    },
    props: ['key', 'log', 'data'],
    data() {
        return {
            color: '',
        };
    },
    computed: {
        ...mapGetters(['isTradesperson']),
        ...mapGetters(USERS_STORE, ['user', 'userTransactionLogLastTimeCategory']),
        createdDate() {
            const d = new Date(this.log.dateCreated);
            return moment(d).format('ddd, MMM D YYYY');
        }
    },
    created() {
    },
    methods: {
        getColor() {
            if (this.log?.transactionType === 'mi') {
                this.color = '#FFA500';
            }
            else if (this.log?.transactionType === 'mo') {
                this.color = '#D45555';
            }
            return this.color;
        },
        getClassSign(log) {
            const action = log.action.toUpperCase();
            return TransactionLogOperatorEnum[action];
        },
        getClassType(log) {
            const type = log.action.toUpperCase();
            return TransactionLogOperatorEnum[type];
        },
        getAmount(log) {
            let value = log.amount;
            if (log.rawAmount && this.isTradesperson) {
                value = log.rawAmount;
                if (value === '0.00') {
                    value = log.amount;
                }
            }
            return value;
        }
    }
});
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
    _useCssVars(_ctx => ({
        "2e75559f": (_ctx.getColor())
    }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__
    ? (props, ctx) => { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
export default __default__;
