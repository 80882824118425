import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import BalanceView from '@/core/components/ui/BalanceView.vue';
import { USERS_STORE } from '@/store/modules/users';
import { WALLET_STORE } from '@/store/modules/wallet';
export default defineComponent({
    components: {
        BalanceView
    },
    data() {
        return {
            userCustomerAccount: null,
            userCustomerCurrentBalance: 0,
            isLoading: false
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        getAvailableBalance() {
            return this.userCustomerCurrentBalance;
        },
    },
    methods: {
        ...mapActions(WALLET_STORE, ['setOpenMoneyInDrawer', 'setMoneyInRightbar', 'setOpenMoneyOutDrawer', 'setMoneyOutRightbar']),
        ...mapActions(USERS_STORE, [
            'getUserCustomerAccountDetails',
            'getUserCustomerAccount',
        ]),
        async initialize() {
            this.userCustomerAccount = await this.getUserCustomerAccount(this.user.id);
            if (this.userCustomerAccount?.accountId) {
                const { accountId } = this.userCustomerAccount;
                const response = await this.getUserCustomerAccountDetails({ userId: this.user.id, accountId });
                const { availableBalance } = response;
                this.userCustomerCurrentBalance = availableBalance;
            }
        },
        openMoneyInDrawer() {
            this.setMoneyInRightbar(true);
            this.setOpenMoneyInDrawer(true);
        },
        openMoneyOutDrawer() {
            this.setMoneyOutRightbar(true);
            this.setOpenMoneyOutDrawer(true);
        }
    },
});
