<template>
    <div class="column-layout">
        <p v-if="!hide" class="header">{{header}}</p>
        <slot></slot>
    </div>
</template>
<script>
export default {
  props: ['header', 'hide'],
};
</script>
<style lang="scss" scoped>
.column-layout {
    display:flex;
    flex-direction: column;
    gap: 2rem;

  .header {
      font-size: 28px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0.25px;
      text-align: left;
      color: #0C0F4A;
      margin: 0;
      font-family: Mulish;
  }
}
</style>
