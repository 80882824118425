<template>
    <div class="balance flex-column ai-c jc-c" :class="{'list-style': isListStyle}">
      <div class="balance__list-type flex-column ai-fs" v-if="isListStyle">
        <p class="balance__tag default-text">Project escrow</p>
        <p class="balance__value default-text">{{ $filters.formatToCurrency(balance) }}</p>
        <p class="balance__label default-text">{{label}}</p>
      </div>

      <el-progress type="circle" :percentage="100" :stroke-width="10" color="rgba(255, 165, 0, 0.5)" v-else>
        <template #default>
        <p class="percentage-value default-text">{{ $filters.formatToCurrency(balance) }}<br> <span>{{label}}</span></p>
        </template>
      </el-progress>
    </div>
</template>
<script>
export default {
  props: {
    balance: {
      type: String,
      default: '0',
    },
    label: {
      type: String,
      default: 'Available balance'
    },
    isListStyle: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;
.font-style {
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.25px;
}
.balance {
  width: 100%;
  .el-progress {
    :deep(.el-progress-circle) {
      width: 280px !important;
      height: 280px !important;
    }
  }
  .percentage-value {
    display: block;
    font-size: 38px;
    @extend .font-style;
    text-align: center;

    span {
      font-size: 20px;
      font-weight: 400;
      color:rgba(12, 15, 74, 0.5);
    }
  }
  &__list-type {
    width: 100%;
    height: auto;
    gap: .5rem;
  }
  &__tag {
    font-size: 20px;
    @extend .font-style;
  }
  &__value {
    font-size: 30px;
    @extend .font-style;
    color: #FFA500;
  }
  &__label {
    font-size: 14px;
    @extend .font-style;
    font-weight: 400;
    color: rgba(12, 15, 74, 0.5);
  }
}

@include media-sm-max-width() {
  .balance {
    .el-progress {
        :deep(.el-progress-circle) {
            width: 160px !important;
            height: 160px !important;
        }
    }
    .percentage-value {
        display: block;
        font-size: 24px;

        span {
            font-size: 12px;
        }
    }
  }
}

@include media-xs-max-width() {
  .balance.list-style {
    justify-content: center;
    align-items: center;
    background: #FFF;
    box-shadow: 0px 0px 10px rgba(12, 15, 74, 0.2);
    border-radius: 20px;
    padding: .5rem;
    box-sizing: border-box;

    .balance__list-type {
      width: auto;
      align-items: center;
      justify-content: center;
      gap: 0;

      .balance__tag {
        font-size: 14px;
      }
      .balance__value {
        font-size: 20px;
      }
      .balance__label {
        font-size: 12px;
      }
    }
  }
}
</style>
